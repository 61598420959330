import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ClientService from "../../services/ClientService";
import "./LoginScreenStyle.scss";
import PeopleService from "../../services/PeopleService";
import { LS_KEY_USER_EMAIL } from "../../constants/Keys";

export default function LoginScreen() {
  const { clientName } = useParams();
  const history = useHistory();
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [client, setClient] = useState();

  async function load() {
    let _client = await ClientService.findByName(clientName);
    if (!_client) {
      return history.replace("/");
    } else {
      setClient(_client);
    }
  }

  function handleOnChangeEmail({ target }) {
    setEmail(target.value);
  }

  async function handleOnSubmitForm(event) {
    event.preventDefault();
    setError();
    let people = await PeopleService.findByClientNameAndEmail(
      client.name,
      email
    );
    if (people?.email) {
      localStorage.setItem(LS_KEY_USER_EMAIL, people.email);
      return history.replace("/" + client.name);
    } else {
      setError("Cette adresse est introuvable dans la base de données");
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <div className="login-container">
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong">
              <form
                className="card-body p-5 text-left"
                onSubmit={handleOnSubmitForm}
              >
                <h3 className="mb-5">Identification</h3>

                <div className="mb-4">
                  <label htmlFor="typeEmailX-2">Email</label>
                  <input
                    onChange={handleOnChangeEmail}
                    value={email}
                    required
                    type="email"
                    id="typeEmailX-2"
                    className="form-control form-control-lg"
                  />
                </div>
                {error && <div className="text-danger mb-2">{error}</div>}

                <button
                  className="btn btn-primary btn-lg btn-block"
                  type="submit"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
