import axios from "axios";
import { CLIENT_FIND_URL } from "../constants/Routes";

async function findByName(clientName) {
  try {
    let response = await axios.get(CLIENT_FIND_URL(clientName));
    if (response?.data?.length === 1) {
      return response.data[0];
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}

export default {
  findByName,
};
