import React from "react";
import "./HomeScreenStyle.scss";
import WindowContact from "../../assets/img/window-contact.jpg";

export default function HomeScreen() {
  return (
    <div className="homepage">
      <a href="mailto:window.art.staff@gmail.com ">
        <img alt="Window Contact" src={WindowContact} />
      </a>
    </div>
  );
}
