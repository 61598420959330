import { API_URL } from "../../../../constants/Routes";
import React from "react";
import "./LinkedContentStyle.scss";

export default function LinkedContent({ bloc }) {
  return (
    <div className="row linked-content ">
      <div
        className={`${
          bloc.__component === "columns.text-right-picture-left"
            ? "col-xl-7"
            : "col-xl-5"
        } mt-5 d-flex align-items-center justify-content-start`}
      >
        <div className="px-2 px-lg-4">
          <a href={bloc?.link} target="_blank" rel="noreferrer">
            {bloc.__component === "columns.text-right-picture-left" ? (
              <img
                className="artwork-picture"
                src={API_URL + bloc.picture.url}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: bloc.text_html }} />
            )}
          </a>
        </div>
      </div>
      <div
        className={`${
          bloc.__component === "columns.text-right-picture-left"
            ? "col-xl-5"
            : "col-xl-7"
        } mt-5 d-flex align-items-center justify-content-end`}
      >
        {" "}
        <div className="px-2 px-lg-4">
          <a href={bloc?.link} target="_blank" rel="noreferrer">
            {bloc.__component === "columns.text-right-picture-left" ? (
              <div
                className="ml-0 ml-md-5"
                dangerouslySetInnerHTML={{ __html: bloc.text_html }}
              />
            ) : (
              <img
                className="artwork-picture"
                src={API_URL + bloc.picture.url}
              />
            )}
          </a>
        </div>
      </div>
    </div>
  );
}
