import axios from "axios";
import { VIEW_CREATE } from "../constants/Routes";

async function addView(email, route, clientName) {
  await axios.post(VIEW_CREATE, { email, route, clientName });
}

export default {
  addView,
};
