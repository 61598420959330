import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ClientService from "../services/ClientService";
import PeopleService from "../services/PeopleService";
import { LS_KEY_USER_EMAIL } from "../constants/Keys";
import PageService from "../services/PageService";
import ViewService from "../services/ViewService";

export default function usePage(pageName) {
  const history = useHistory();
  const params = useParams();

  const [page, setPage] = useState();

  async function load() {
    let client = await ClientService.findByName(params.clientName);
    if (!client) {
      return history.replace("/");
    }
    if (client.css) {
      const style = document.createElement("style");
      style.id = "style-client";
      document.head.appendChild(style);
      style.innerHTML = client.css;
    }
    let isAllowed = localStorage.getItem(LS_KEY_USER_EMAIL);
    if (client.restrictive) {
      isAllowed = await PeopleService.findByClientNameAndEmail(
        params.clientName,
        localStorage.getItem(LS_KEY_USER_EMAIL)
      );
    }

    if (!isAllowed) {
      localStorage.clear();
      return history.replace("/" + params.clientName + "/login");
    }
    let _page = await PageService.findByClientNameAndPageName(
      params.clientName,
      pageName || params.pageName
    );

    if (_page) {
      document.title += " - " + params.clientName;
      setPage(_page);
      await ViewService.addView(
        localStorage.getItem(LS_KEY_USER_EMAIL),
        pageName || params.pageName,
        params.clientName
      );
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    load();
    return () => {
      let style = document.getElementById("style-client");
      if (style) {
        document.head.removeChild(style);
      }
    };
  }, []);

  return page;
}
