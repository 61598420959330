import React from "react";
import { API_URL } from "../../../../constants/Routes";
import Text from "../Text";

export default function ArtworkBloc({ bloc }) {
  return (
    <div className="row">
      <div className="col-xl-5 mt-5 d-flex align-items-center justify-content-center">
        <div className="px-2 px-lg-4">
          {bloc.__component === "columns.text-right-picture-left" ? (
            <img className="artwork-picture" src={API_URL + bloc.picture.url} />
          ) : (
            <Text>{bloc.text_html}</Text>
          )}
        </div>
      </div>
      <div className="col-xl-7 mt-5 d-flex align-items-center justify-content-center">
        <div className="px-2 px-lg-4">
          {bloc.__component === "columns.text-right-picture-left" ? (
            <Text>{bloc.text_html}</Text>
          ) : (
            <img className="artwork-picture" src={API_URL + bloc.picture.url} />
          )}
        </div>
      </div>
    </div>
  );
}
