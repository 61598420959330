import axios from "axios";
import { PAGE_FIND_URL } from "../constants/Routes";

async function findByClientNameAndPageName(clientName, pageName) {
  try {
    let response = await axios.get(PAGE_FIND_URL(clientName, pageName));
    return response.data;
  } catch (error) {
    return undefined;
  }
}

export default {
  findByClientNameAndPageName,
};
