import React from "react";
import usePage from "../../../hooks/usePage";
import HtmlWithExperience from "../components/HtmlWithExperience";
import Scroll from "../../../assets/img/scroll.png";
import ParallaxBackground from "../components/ParallaxBackground/ParallaxBackground";
import "./ClientHomeStyle.scss";
import Newsletter from "../components/Newsletter/Newsletter";
import LearnMore from "../components/LearnMore/LearnMore";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Disclaimer from "../components/Disclaimer/Disclaimer";
import { API_URL } from "../../../constants/Routes";
import { isMobile } from "react-device-detect";
import Text from "../components/Text";
import Experience from "../components/Experience";
import Agent from "../components/Agent/Agent";
import LinkedContent from "../components/LinkedContent/LinkedContent";

export default function ClientHome() {
  const page = usePage("home");

  return (
    <HtmlWithExperience page={page}>
      <Header />
      <ParallaxBackground image={API_URL + page?.top_background?.url}>
        <div className="client-logo-container">
          <img alt="Window" src={API_URL + page?.client_logo?.url} />
        </div>

        <div className="scroll-container">
          <img alt="Scroll" src={Scroll} />
        </div>

        <div className="stripes-container">
          <Experience
            id={
              isMobile
                ? page?.stripes_experience_mobile
                : page?.stripes_experience_desktop
            }
          />
        </div>
      </ParallaxBackground>
      <Disclaimer>VIEWING ROOMS BY WINDOW™</Disclaimer>
      <div className="presentation">
        <div className="content">
          <h1>{page?.title_intro}</h1>
          <h2>{page?.subtitle_intro}</h2>
          <div className="introduction">
            <Text>{page?.description_intro_1}</Text>
          </div>
          <div className="row">
            <div className="col-xl-8 d-flex justify-content-start">
              <Text>{page?.description_intro_2}</Text>
            </div>
            <div className="col-xl-4 text-center mt-5 mt-lg-0">
              <img
                className="w-100"
                alt="Artist"
                src={API_URL + page?.artist_picture_1?.url}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="rolling-container">
        <Experience
          id={
            isMobile
              ? page?.rolling_experience_mobile
              : page?.rolling_experience_desktop
          }
          bucketsMobile="[700, 800, 900, 1000, 1100, 1200, 1300]"
        />
      </div>

      <div className="about">
        <div className="content">
          <div className="row">
            <div className="col-xl-6 d-flex align-items-center">
              <img alt="About" src={API_URL + page?.artist_picture_2?.url} />
            </div>
            <div className="col-xl-6 d-flex align-items-center">
              <div className="mx-0 mx-lg-5">
                <h2 className="mt-5 mt-lg-0">{page?.title_about}</h2>
                <Text>{page?.description_about}</Text>
                <div className="author">{page?.author_about}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {page?.agent && <Agent agent={page?.agent} />}
      <div className="content">
        {page?.linked_content && (
          <div>
            {page?.linked_content.map((bloc) => (
              <LinkedContent bloc={bloc} />
            ))}
          </div>
        )}
      </div>
      <LearnMore title={page?.banner_title} ctaHtml={page?.banner_cta_html} />
      <Newsletter background={API_URL + page?.newsletter_background?.url} />
      <Footer />
    </HtmlWithExperience>
  );
  //return <HtmlWithExperience page={page} />;
}
