import ParallaxBackground from "../ParallaxBackground/ParallaxBackground";
import "./NewsletterStyle.scss";
import axios from "axios";
import { SUBSCRIBE_NEWSLETTER } from "../../../../constants/Routes";
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export default function Newsletter({ background }) {
  const params = useParams();

  const initialState = {
    email: "",
    firstname: "",
    lastname: "",
    country: "",
    client: params.clientName,
  };

  const [data, setData] = useState({ ...initialState });

  async function handleSubscribe(event) {
    event.preventDefault();

    try {
      await axios.post(SUBSCRIBE_NEWSLETTER, data);
      toast.success(
        `Thanks ${data.firstname}, you are now subscribed to the mailing list`
      );
      console.log(initialState);
      setData({ ...initialState });
    } catch (error) {
      toast.error(
        "Oops, an error occurred. Please refresh your browser or try again later"
      );

      console.error(error);
    }
  }

  function handleSetData(value, inputName) {
    setData({
      ...data,
      [inputName]: value,
    });
  }

  return (
    <ParallaxBackground image={background}>
      <div className="newsletter-container">
        <div className="content">
          <div className="row">
            <div className="col-xl-5">
              <p>
                Join the <strong>mailing list</strong> for updates about online
                exhibitions and more.
              </p>
            </div>
            <div className="col-xl-7 text-center text-lg-right mt-4 mt-lg-0">
              <form onSubmit={handleSubscribe}>
                <div>
                  <input
                    required
                    type="text"
                    value={data.firstname}
                    placeholder="First name"
                    onChange={(event) =>
                      handleSetData(event.target.value, "firstname")
                    }
                  />
                  <input
                    required
                    type="text"
                    value={data.lastname}
                    placeholder="Last name"
                    onChange={(event) =>
                      handleSetData(event.target.value, "lastname")
                    }
                  />
                </div>
                <div>
                  <input
                    required
                    type="email"
                    value={data.email}
                    placeholder="Email"
                    onChange={(event) =>
                      handleSetData(event.target.value, "email")
                    }
                  />
                  <input
                    required
                    type="text"
                    value={data.country}
                    placeholder="Country"
                    onChange={(event) =>
                      handleSetData(event.target.value, "country")
                    }
                  />
                </div>

                <div>
                  <button className="button">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ParallaxBackground>
  );
}
