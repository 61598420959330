import { SERVER_URL } from "../../../constants/Routes";
import { isMobile } from "react-device-detect";

export default function Experience({
  id,
  bucketsDesktop = "[800, 1000, 1200, 1400, 1600, 1800, 2000]",
  bucketsMobile = "[400, 500, 600, 700, 800, 900, 1000]",
}) {
  return id ? (
    <img
      src={`${SERVER_URL}/assets/${id}/poster.svg`}
      motionly-params={`
        {
          "loadMode": "poster",
          "assetsBaseUrl": "${SERVER_URL}/assets/${id}/",
          "dimensions":
        {
          "buckets": ${isMobile ? bucketsMobile : bucketsDesktop} 
        }
      }`}
    />
  ) : (
    <></>
  );
}
