export const API_URL = process.env.REACT_APP_API_URL;
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const CLIENT_FIND_URL = (clientName) =>
  API_URL + "/clients/" + clientName;

export const PEOPLE_FIND_URL = (clientName, email) =>
  API_URL + "/people/" + clientName + "/" + email;

export const PAGE_FIND_URL = (clientName, pageName) =>
  API_URL + "/pages/" + clientName + "/" + pageName;

export const VIEW_CREATE = API_URL + "/views";
export const SUBSCRIBE_NEWSLETTER = API_URL + "/newsletters";
