import "./FooterStyle.scss";

export default function Footer() {
  function handleLogout(event) {
    event.preventDefault();
    localStorage.clear();
    window.location = "/";
  }

  return (
    <footer>
      <p>
        © Window. {new Date().getFullYear()} |{" "}
        <a href="mailto:window.art.staff@gmail.com">Contact us !</a>
      </p>
    </footer>
  );
}
