import Text from "../Text";
import { API_URL } from "../../../../constants/Routes";
import React from "react";
import "./AgentStyle.scss";

export default function Agent({ agent }) {
  return (
    <div className="agent-container ">
      <h3>{agent?.title}</h3>
      <img className="agent-picture" src={API_URL + agent?.logo?.url} />
      <Text>{agent?.description}</Text>
    </div>
  );
}
