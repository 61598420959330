import React, { useCallback, useEffect } from "react";

export default function HtmlWithExperience({ page, children }) {
  const onRefChange = useCallback((node) => {
    if (node) {
      let exists = document.getElementById("script-client");
      if (!exists) {
        let s = document.createElement("script");
        s.id = "script-client";
        s.type = "text/javascript";
        s.src = "/motionly.js";
        document.head.appendChild(s);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      let exists = document.getElementById("script-client");
      if (exists) {
        document.head.removeChild(exists);
      }
    };
  }, []);

  return page?.id ? (
    <div ref={onRefChange}>{children}</div>
  ) : (
    <div>Loading ...</div>
  );
}
