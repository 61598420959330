import "./ParallaxBackgroundStyle.scss";

export default function ParallaxBackground({
  image,
  className,
  minHeight,
  children,
}) {
  return (
    <div
      className={`parallax-background ${className}`}
      style={{
        backgroundImage: `url(${image})`,

        minHeight,
      }}
    >
      {children}
    </div>
  );
}
