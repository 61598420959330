import axios from "axios";
import { PEOPLE_FIND_URL } from "../constants/Routes";

async function findByClientNameAndEmail(clientName, email) {
  try {
    let response = await axios.get(PEOPLE_FIND_URL(clientName, email));
    if (response?.data?.length === 1) {
      return response.data[0];
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}

export default {
  findByClientNameAndEmail,
};
