import React from "react";
import usePage from "../../../hooks/usePage";
import HtmlWithExperience from "../components/HtmlWithExperience";
import Header from "../components/Header/Header";
import ParallaxBackground from "../components/ParallaxBackground/ParallaxBackground";
import "./ClientPageStyle.scss";
import Disclaimer from "../components/Disclaimer/Disclaimer";
import LearnMore from "../components/LearnMore/LearnMore";
import Newsletter from "../components/Newsletter/Newsletter";
import Footer from "../components/Footer/Footer";
import { API_URL } from "../../../constants/Routes";
import Text from "../components/Text";
import Experience from "../components/Experience";
import { isMobile } from "react-device-detect";
import Agent from "../components/Agent/Agent";
import LinkedContent from "../components/LinkedContent/LinkedContent";
import ArtworkBloc from "../components/ArtworkBloc/ArtworkBloc";

export default function ClientPage() {
  const page = usePage();

  return (
    <HtmlWithExperience page={page}>
      <Header />
      <ParallaxBackground
        className="top"
        image={API_URL + page?.top_background.url}
      >
        <div className="padding" />
      </ParallaxBackground>
      <div className="artwork-presentation">
        <div className="content">
          <div className="row">
            <div
              dangerouslySetInnerHTML={{ __html: page?.technical_intro_html }}
              className="col-xl-5  mb-5 mb-lg-0"
            />
            <div className="col-xl-7 mt-5 mt-lg-0">
              <h1>{page?.title_intro}</h1>
              <h2>{page?.subtitle_intro}</h2>
              <Text>{page?.description_intro_1}</Text>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-xl-5 mt-5 d-flex align-items-center pr-0 pr-lg-5">
            <Text>{page?.description_intro_2}</Text>
          </div>
          <div className="col-xl-7 mt-5">
            <img
              className="artwork-picture"
              src={API_URL + page?.picture_intro.url}
            />
          </div>
        </div>
        <div className="disclaimer-container">
          <Disclaimer>EXPERIENTIAL LOOK BY WINDOW™</Disclaimer>
        </div>
      </div>
      <div className="zoom-container">
        <Experience
          id={
            isMobile
              ? page?.zoom_experience_mobile
              : page?.zoom_experience_desktop
          }
        />
      </div>
      <div className="content">
        {page?.artwork_blocs?.map((bloc) => (
          <ArtworkBloc bloc={bloc} />
        ))}
        {page?.agent && <Agent agent={page?.agent} />}
        <div>
          {page?.linked_content?.map((bloc) => (
            <LinkedContent bloc={bloc} />
          ))}
        </div>
      </div>

      <div className="mt-5">
        <LearnMore title={page?.banner_title} ctaHtml={page?.banner_cta_html} />
        <Newsletter background={API_URL + page?.newsletter_background?.url} />
        <Footer />
      </div>
    </HtmlWithExperience>
  );
}
