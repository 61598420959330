import "./HeaderStyle.scss";
import LogoImage from "../../../../assets/img/window-logo-small.png";
import { useHistory } from "react-router-dom";

export default function Header() {
  const history = useHistory();

  return (
    <div className="window-logo-container">
      <img alt="Window" src={LogoImage} onClick={() => history.push("/")} />
    </div>
  );
}
