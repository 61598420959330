import "./LearnMoreStyle.scss";

export default function LearnMore({ title, ctaHtml }) {
  return (
    <div className="more">
      <p>{title}</p>
      <div dangerouslySetInnerHTML={{ __html: ctaHtml }} />
    </div>
  );
}
