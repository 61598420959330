import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginScreen from "./app/Login/LoginScreen";
import HomeScreen from "./app/Home/HomeScreen";
import ClientPage from "./app/Client/ClientPage/ClientPage";
import ClientHome from "./app/Client/ClientHome/ClientHome";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { LS_KEY_USER_EMAIL } from "./constants/Keys";
import { random } from "./utils/Utils";

function App() {
  useEffect(() => {
    let tmpId = localStorage.getItem(LS_KEY_USER_EMAIL);
    if (!tmpId) {
      localStorage.setItem(LS_KEY_USER_EMAIL, random(12));
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/:clientName" component={ClientHome} />
        <Route exact path="/:clientName/login" component={LoginScreen} />
        <Route
          exact
          path="/:clientName/page/:pageName"
          component={ClientPage}
        />
        <Route exact path="/" component={HomeScreen} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <ToastContainer hideProgressBar />
    </BrowserRouter>
  );
}

export default App;
